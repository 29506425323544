import { AuthenticationProvider } from "@artifactlabs/react-auth";
import { ReactNode } from "react";
import { useRecoilState } from "recoil";
import { useIsClient } from "usehooks-ts";

import authAtom from "@/recoil/auth";

export interface Props {
  children?: ReactNode;
}

const AppAuthenticationProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [authState, setAuthState] = useRecoilState(authAtom);
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  return (
    <>
      <AuthenticationProvider
        redirectUrl={`${window.location.origin}/callback`}
        onRedirectCallback={appState => {
          setAuthState({
            ...authState,
            returnTo:
              appState && appState.returnTo
                ? appState.returnTo
                : `${window.location.pathname}${window.location.search}`,
          });
        }}
      >
        {children}
      </AuthenticationProvider>
    </>
  );
};

export default AppAuthenticationProvider;
